<template>
  <template v-if="packages.length > 0">
    <h4 class="text-center mb-4">{{ $t(`forms.buyPackages.prepaidRequests`) }}</h4>
    <Form :initial-values="formSchema"
          @submit="submit"
          ref="packages">
      <div class="text-center">
        <span class="paymentDetail-param paymentDetail-param_main">
          {{ $t('forms.buyPackages.wantSend') }}
        </span>
          <Field
            as="select"
            name="package"
            id="packages"
            class="form-control mb-0 form-group d-inline-flex w-auto mx-2"
            v-model="selectedPackage"
          >
            <option
              :key="item.package_id"
              :value="item.package_id"
              v-for="item of packages"
            >{{ item.messages || item.request }}
            </option>
          </Field>
          <span class="paymentDetail-param paymentDetail-param_main">
            {{ $t(`forms.buyPackages.requests`) }}
          </span>
      </div>
      <div v-if="selectedPackageObject" class="font-weight-bold mt-3 text-center paymentDetail-param_plan paymentDetail-param_plan_main">
        {{ selectedPackageObject.localized_price }}
      </div>
      <div v-if="selectedPackageObject && selectedPackageObject.charge_option === 'monthly'" class="font-weight-bold m-0 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.monthly')}}
      </div>
      <div v-if="selectedPackageObject && selectedPackageObject.charge_option === 'weekly'" class="font-weight-bold m-0 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.weekly')}}
      </div>
      <div v-if="selectedPackageObject" class="text-center mt-2 mb-3">
        <div v-if="selectedPackageObject.localized_price_per_request">
          {{ $t('forms.buyPackages.price_per_request') }}: {{selectedPackageObject.localized_price_per_request}}
        </div>
        <div v-if="selectedPackageObject.term">
          <hint title="forms.buyPackages.terms_hint" placement="top">
            {{$t('forms.buyPackages.lookup_terms_label')}}: {{$t('forms.buyPackages.terms.' + selectedPackageObject.term)}}
          </hint>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          class="btn btn-primary btn-lg shadow-2"
          :disabled="isDisabled"
          style="position: relative"
        >
          <spinner-component v-if="isDisabled"></spinner-component>
          {{$t('forms.buyPackages.buy')}}
        </button>
      </div>
      <div class="paymentDetail-info" v-if="selectedPackageObject && (selectedPackageObject.charge_option === 'monthly' || selectedPackageObject.charge_option === 'weekly')">
        <div class="pt-3 d-flex justify-content-between">
          <alert type="info" text="forms.buyPackages.monthlyCommittedPlanInfo" class="mb-1" />
        </div>
      </div>
    </Form>
  </template>
  <slot></slot>
</template>

<script>
import { computed, ref, defineAsyncComponent } from 'vue'
import { Form, Field } from 'vee-validate'
import { useMessagePackages } from '@/application/composables/messagePackages.js'
import { store } from '@/application/store'

export default {
  name: 'MessagePackagesForm',
  components: {
    Form,
    Field,
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    packages: {
      type: Array,
    },
  },
  setup(props) {
    const agreement = ref(false)
    const selectedPackage = ref(props.packages.length > 0 ? props.packages[0].package_id : null)
    const selectedPackageObject = computed(() => {
      const selectedPackageObject = props.packages.find(i => i.package_id === selectedPackage.value)
      return selectedPackageObject ? { ...selectedPackageObject } : null
    })

    const {
      submit,
      isDisabled,
      errorMessage,
    } = useMessagePackages('lookup')
    return {
      submit: async(value) => {
        isDisabled.value = true
        try {
          const url = await submit(value)
          window.Paddle.Checkout.open({
            override: url,
            disableLogout: true,
            successCallback: function() {
              store.dispatch('switchOnDashboardReload')
            },
          })
          isDisabled.value = false
        } catch (exception) {
          isDisabled.value = false
        }
      },
      isDisabled,
      errorMessage,
      selectedPackageObject,
      selectedPackage,
      agreement,
    }
  },
}
</script>
